@use '../abstracts/mixins';
.about-us {
  --about-us__gap: clamp(var(--space-16), 5vw, var(--space-128));
  padding-inline: clamp(var(--space-8), 5vw, var(--space-128));
  display: grid;
  grid-gap: var(--about-us__gap);
  grid-template-columns: repeat(2, 1fr);
  &__content {
    display: grid;
    grid-gap: var(--about-us__gap);
    grid-column: 1 / span 2;
  }
  &__title {
    @extend .t-title-1;
  }
  &__text {
    @extend .t-body;
  }
  &__image {
    display: grid;
    grid-gap: calc(var(--about-us__gap) / 2);
    margin: 0;
    img {
      @extend .wirnshofer-image;
    }
    &--image-1 {
    }
    &--image-2 {
    }
  }
  &__caption {
    @extend .t-caption-1;
    text-transform: uppercase;
    color: var(--color-beige-dark);
  }
}

@include mixins.respond-to('s') {
  .about-us {
    align-items: flex-start;
    grid-template-rows: repeat(4, min-content);
    &__content {
      grid-column: 1/1;
      padding-bottom: var(--about-us__gap);
      padding-left: var(--about-us__gap);
    }
    &__text {
      max-width: 28ch;
    }
    &__image--image-1 {
      grid-column: 2/2;
      grid-row: 1/-1;
      margin-top: calc(var(--about-us__gap) * 2.5);
    }
    &__image--image-2 {
      grid-column: 1/1;
      grid-row: 2/4;
    }
  }
}
