@use '../abstracts/mixins';
.faq {
  --faq-gap: clamp(var(--space-8), 3vw, var(--space-16));
  display: grid;
  grid-gap: var(--faq-gap);
  padding-block: calc(var(--faq-gap) * 2);
  padding-inline: calc(var(--faq-gap) * 2);
  --fs: clamp(14px, 4vw, 24px);
  font-size: var(--fs);
  &__entry {
    border: 1.5px solid var(--color-beige-middle);
    border-radius: calc(2 * var(--fs));
    transition: all 400ms ease-out;
    summary {
      &::-webkit-details-marker {
        display: none;
      }
      padding: var(--faq-gap);
      list-style: none;
      @extend .t-title-2;
      font-size: clamp(14px, 4vw, 24px);
      display: grid;
      grid-gap: 2ch;
      gap: var(--faq-gap);
      grid-template-columns: calc(2 * var(--fs)) repeat(4, 1fr);
      align-items: center;
      cursor: pointer;
      span {
        grid-column: 2/-1;
      }

      &:before {
        grid-column: 1/1;
        justify-self: center;
        align-self: flex-start;
        transition: all 300ms ease-out;
        width: calc(2 * var(--fs));
        aspect-ratio: 1;
        height: auto;
        content: '';
        aspect-ratio: 1;
        padding: calc(1 * var(--fs));
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='54' height='54' fill='none' viewBox='0 0 54 54'%3E%3Cpath stroke='%234A4A4A' stroke-width='1.5' d='m4.5 18 22.3 22.3L49 18'/%3E%3C/svg%3E");
        background-position: center;
        background-size: 60%;
        border-radius: 4ch;
        border: 1px solid var(--color-beige-middle);
      }
    }
    p {
      @extend .t-body;
      padding: var(--faq-gap);
      padding-left: calc(2 * var(--faq-gap) + 2 * var(--fs));
      padding-right: calc(2 * var(--fs));
    }
    &[open] {
      summary {
        &:before {
          transform: rotate(-180deg);
        }
      }

      p {
        padding-top: 0;
      }
    }
  }
}
@include mixins.respond-to('s') {
  .faq {
    --faq-gap: clamp(var(--space-16), 2vw, var(--space-64));
    --fs: var(--title-2-fs);
    display: grid;
    gap: var(--faq-gap);
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 1fr;
    align-items: flex-start;
    font-size: var(--fs);

    padding-inline-start: calc(var(--history-gap) * 2);
    &__entry {
      grid-column: 2/-2;
    }
    summary {
      font-size: var(--title-2-fs);
    }
  }
}
