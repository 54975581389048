.carousel {
  list-style: none;
  padding-left: 0;
  margin: 0;
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: auto;
  -ms-scroll-snap-points-x: repeat(100%);
  scroll-snap-points-x: repeat(100%);
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  &__slide {
    flex-shrink: 0;
    width: 100vw;
    height: 100%;
    scroll-snap-align: start;
  }
}
