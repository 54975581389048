.marquee {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  --gap: 2rem;
  gap: var(--gap);
  margin-bottom: 3rem;
  --marquee-fs: clamp(
    28px,
    calc(1.75rem + ((1vw - 4.8px) * 2.7210884354)),
    42px
  );
  & + & {
    margin-top: var(--marquee-fs);
  }
  &__wrapper {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: nowrap;
    gap: var(--gap);
    min-width: 100%;
    position: relative;
    animation-name: marquee;
    animation-duration: 35s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-direction: alternate;
  }
  &__slide {
    padding-inline: 1.5em;
    grid-row: 1;
    display: inline-flex;
    gap: 1em;
    font-size: var(--marquee-fs);
  }
  &__text {
    display: block;
    word-break: keep-all;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    white-space: nowrap;
  }
  &__image {
    display: block;
    width: 112px;
    height: auto;
    max-width: 100%;
    object-fit: contain;
  }

  &[data-direction='reverse'] {
    .marquee {
      &__wrapper {
        animation-direction: alternate-reverse;
      }
    }
  }
}
@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
