@use '../abstracts/mixins';

.products,
.images {
  min-height: 20vh;
  padding-left: clamp(var(--space-16), 5vw, var(--space-128));
  margin-bottom: clamp(var(--space-16), 5vw, var(--space-32));
  .swiper {
    display: grid;
    gap: 32px;
    position: relative;
    cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='84' height='84' fill='none' viewBox='0 0 84 84'%3E%3Cpath stroke='%23000' d='M22 42.5h40m0 0L48.7 56M62 42.5 48.7 29M62 42.5H22m0 0L35.3 29M22 42.5 35.3 56'/%3E%3Ccircle cx='42' cy='42' r='41.3' stroke='%23D8AB76' stroke-width='1.5' transform='rotate(-180 42 42)'/%3E%3C/svg%3E")
        32 32,
      pointer;
  }

  .swiper-wrapper {
    margin-bottom: clamp(var(--space-16), 2vw, var(--space-64));
  }

  .swiper-scrollbar {
    height: 1.5px;
    background-color: var(--color-beige-middle);
    max-width: max(20vw, 180px);
    left: 50%;
    transform: translate(-50%, 0);

    .swiper-scrollbar-drag {
      background-color: var(--color-beige-dark);
      height: 3px;
      margin-top: -0.75px;
    }
  }

  &__category {
    margin-bottom: clamp(var(--space-64), 5vw, var(--space-128));
  }
  &__slider {
    margin-left: calc(clamp(var(--space-16), 5vw, var(--space-128)) * -1);
    .swiper-slide:first-child {
      margin-left: calc(clamp(var(--space-16), 5vw, var(--space-128)));
    }
  }
}
.product {
  width: 100%;

  &__item {
  }

  &__image {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    margin: 0;

    &:after {
      content: '';
      aspect-ratio: 1;
      grid-row: 1/1;
      grid-column: 1/1;
      border-radius: 100vmin;
      z-index: -2;
      background-color: var(--color-beige-soft);
    }

    picture {
      aspect-ratio: 1;
      grid-row: 1/1;
      grid-column: 1/1;
      border-radius: 100vmin;
      z-index: -1;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 100%;
        width: 75%;
        height: auto;
        object-fit: cover;
      }
    }
  }

  &__caption {
    grid-row: 1/1;
    grid-column: 1/1;
    align-self: flex-end;
    color: var(--color-beige-dark);
    @extend .t-caption-1;
    max-width: 12ch;
    display: none;
  }

  &__description {
    display: none;
  }

  &__title {
    @extend .t-title-2;
    max-width: 15ch;
  }

  &__text {
    @extend .t-body;
  }
}
.product--category,
.image--category {
  gap: clamp(16px, 3vw + 1px, 60px);
  display: grid;
  align-content: flex-start;

  .product {
    &__text {
      max-width: 30ch;
    }
  }
}

.product--modal {
  --product-gap: clamp(var(--space-16), 5vw, var(--space-128));
  display: grid;
  gap: var(--product-gap);
  .product {
    &__image {
      img {
        border-radius: 0;
      }
    }
    &__caption {
      height: 0;
      opacity: 0;
      overflow: hidden;
    }
    &__description {
      display: grid;
      gap: var(--product-gap);
      align-content: flex-start;
      align-self: center;
    }
    &__title {
      @extend .t-title-2;
      &,
      span {
        /* These are technically the same, but use both */
        overflow-wrap: break-word;
        word-wrap: break-word;
        -ms-word-break: break-all;
        /* This is the dangerous one in WebKit, as it breaks things wherever */
        word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;
        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
      }
      span {
        display: block;
      }
    }
    &__text {
      @extend .t-body;
    }
  }
  .form-response {
    display: none;
  }
  .form button {
    margin-top: var(--modal-padding);
  }
}

.store-image {
  aspect-ratio: 16/9;
  picture {
    aspect-ratio: 16/9;
    grid-row: 1/1;
    grid-column: 1/1;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      border-radius: 80px;
      max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.store {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: center;
  gap: clamp(32px, 3vw, 46px) 22px;
  svg {
    grid-column: 1/1;
    //width: clamp();
  }
  &__opening,
  &__location {
    grid-column: 2/2;
  }
  .content__text {
    line-height: 150%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }
}

@media (min-width: 480px) and (orientation: landscape) {
  .product--modal {
    grid-template-columns: repeat(2, 1fr);
  }
}

@include mixins.respond-to('s') {
  .products,
  .images {
    display: grid;
    align-items: flex-start;
    grid-template-columns: max-content 1fr;
    gap: clamp(16px, 2vw, 32px);

    &__category,
    &__slider {
      grid-row: 1/1;
    }

    &__slider {
      grid-row: 1/1;
      overflow: hidden;
    }
    &__slider {
      margin-left: 0;

      .swiper-slide:first-child {
        margin-left: 0;
      }
    }
    .swiper-scrollbar {
    }
  }
  .products--reverse,
  .images--reverse {
    padding-right: clamp(var(--space-16), 5vw, var(--space-128));
    padding-left: 0;
    grid-template-columns: 1fr max-content;
    gap: clamp(var(--space-16), 4vw, var(--space-64));

    .swiper-scrollbar {
    }

    .products,
    .images {
      &__category {
        grid-column: 2/2;
      }

      &__slider {
        grid-column: 1/1;
      }
    }
  }
  .product--modal {
    grid-template-columns: repeat(2, 1fr);
  }
  .store {
    grid-template-columns: 1fr;
    padding-inline: clamp(16px, 5vw, 32px);
    padding-top: clamp(16px, 5vw, 64px);
    gap: clamp(var(--space-32), 2vw, var(--space-64));

    &__opening,
    &__location {
      grid-column: 1/1;
    }
  }
}

@include mixins.respond-to('l') {
  .store {
    padding-inline: clamp(32px, 5vw, 96px);
  }
}
.product--uhren {
  .product__caption {
    display: block;
  }
}
