@use '../abstracts/mixins';

.swiper--pure-slider {
  padding-left: clamp(var(--space-16), 5vw, var(--space-128));
  padding-bottom: clamp(var(--space-16), 5vw, var(--space-128));
  .swiper-wrapper {
    transition-timing-function: linear !important;
  }
}
.auto-slide {
  overflow: hidden;
  width: 100%;
  height: 100%;
  &__image {
    display: grid;
    @extend .t-title-1;
    grid-template-columns: 3ch 1.7ch 1ch 1fr;
    grid-template-rows: 1fr 1ch auto;
    margin: 0;
    &:before {
      content: attr(data-number);
      aspect-ratio: 1;
      padding: 1ch;
      font-size: var(--title-1-fs);
      grid-row: 1/5;
      grid-column: 1/3;
      border-radius: 100vmin;
      z-index: 4;
      border: 1.5px solid var(--color-beige-middle);
      background-color: rgba(254, 249, 239, 0.5); // color beige
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: flex-end;
    }

    img {
      max-width: 100%;
      width: 100%;
      object-fit: cover;
      aspect-ratio: 1;
      z-index: -1;
      grid-column: 2/-1;
      grid-row: 1/1;
    }
  }
  &__caption {
    align-self: flex-end;
    color: var(--color-beige-dark);
    @extend .t-caption-1;
    grid-column: 4/-1;
    grid-row: -1/-1;
  }
  .swiper-scrollbar {
    @extend .t-title-1;
    right: 1%;
    left: auto;
    height: 2px;
    max-width: calc(100% - 3ch);
    background-color: var(--color-beige-middle);
    .swiper-scrollbar-drag {
      background-color: var(--color-beige-dark);
      height: 4px;
      margin-top: -1px;
    }
  }
  .swiper-wrapper {
    margin-bottom: clamp(var(--space-16), 5vw, var(--space-128));
  }
}
.swiper--carousel {
  position: relative;
  margin-bottom: 3rem;
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='84' height='84' fill='none' viewBox='0 0 84 84'%3E%3Cpath stroke='%23000' d='M22 42.5h40m0 0L48.7 56M62 42.5 48.7 29M62 42.5H22m0 0L35.3 29M22 42.5 35.3 56'/%3E%3Ccircle cx='42' cy='42' r='41.3' stroke='%23D8AB76' stroke-width='1.5' transform='rotate(-180 42 42)'/%3E%3C/svg%3E")
      32 32,
    pointer;
  min-height: min(100%, 500px);
  .swiper-scrollbar {
    @extend .t-title-1;
    height: 2px;
    max-width: 280px;
    background-color: var(--color-beige-middle);
    position: absolute;
    bottom: 0%;
    left: clamp(var(--space-16), 5vw, var(--space-128));
    .swiper-scrollbar-drag {
      background-color: var(--color-beige-dark);
      height: 4px;
      margin-top: -1px;
    }
  }
  .swiper-wrapper {
    margin-bottom: clamp(var(--space-16), 5vw, var(--space-128));
  }
}
@include mixins.respond-to('s') {
  .swiper--carousel {
    min-height: unset;

    .swiper-scrollbar {
      max-width: 300px;
      right: auto;
      bottom: 0%;
    }
  }
}
@include mixins.respond-to('m') {
  .swiper--carousel {
    .swiper-scrollbar {
      max-width: max(350px, 15vw);
      bottom: 22%;
    }
  }
}
@include mixins.respond-to('l') {
  .swiper--carousel {
    .swiper-scrollbar {
      max-width: max(350px, 15vw);
      bottom: 22%;
    }
  }
}
