@use '../abstracts/mixins';

// .content {grid-area: content;};
// .image {grid-area: image;};

.l-two-col {
  display: grid;
  grid-gap: var(--space-16);
  &__col-1 {
  }

  &__col-2 {
  }
}
.l-two-col--reverse {
  .l-two-col {
    &__col-1,
    &__col-2 {
      grid-row: 1/1;
    }
    &__col-1 {
      grid-row: 2/2;
      padding-inline: clamp(var(--space-16), 5vw, var(--space-128));
    }
    &__col-2 {
      grid-row: 1/1;
    }
  }
}
.l-two-col--overlapping {
  grid-template-rows: auto 150px auto;
  .l-two-col {
    &__col-1,
    &__col-2 {
      grid-column: 1/1;
    }

    &__col-1 {
      grid-row: 1/3;
    }

    &__col-2 {
      grid-row: 2/3;
    }
  }
  &.l-two-col--reverse {
    &__col-1 {
      grid-row: 2/3;
    }

    &__col-2 {
      grid-row: 1/3;
    }
  }
}

.l-two-col--padding {
  padding-inline: clamp(var(--space-8), 5vw, var(--space-128));
}

.wirnshofer,
.individuell {
  grid-template-rows: min-content 32px min-content;
  grid-template-columns: 1fr;
  .l-two-col {
    &__col-1,
    &__col-2 {
      grid-column: 1/1;
    }
    &__col-1 {
      grid-row: 2/4;
      z-index: 1;
    }

    &__col-2 {
      grid-row: 1/3;
    }
  }

  margin-bottom: clamp(var(--space-16), 5vw, var(--space-32));
}
.wirnshofer {
  grid-template-rows:
    min-content calc(
      2.2 * (var(--title-1-fs) * var(--title-1-lh)) + var(--space-16)
    )
    min-content;

  grid-template-columns: 2fr 1fr 2fr;
  .content {
    grid-column: 1/-1;
    &__title {
      max-width: 13ch;
    }
  }
  .image {
    grid-column: 2/-1;
  }

  img {
    opacity: 0.8;
  }
}
.individuell {
  grid-template-rows: min-content 128px min-content;
}
.kollektion {
  align-items: flex-end;
  .l-two-col__col-1 {
    padding-inline-end: clamp(var(--space-16), 5vw, var(--space-128));
  }
}
.section-title {
  @extend .t-title-1;
  padding-inline: clamp(var(--space-16), 5vw, var(--space-128));
  padding-block: clamp(var(--space-16), 5vw, var(--space-64));
  max-width: max(480px, 50vw);
}

@include mixins.respond-to('s') {
  .wirnshofer,
  .l-two-col {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-gap: var(--space-32);
    margin-block-end: 32px;
  }
  .individuell,
  .l-two-col--overlapping {
    grid-template-columns: 1fr repeat(4, 75px) 1fr;
    grid-template-rows: auto;
    align-content: flex-end;
    align-items: flex-end;
    .l-two-col {
      &__col-1,
      &__col-2 {
        grid-row: 1/1;
      }

      &__col-1 {
        grid-column: 1 / span 5;
      }

      &__col-2 {
        grid-column: span 5/-1;
      }
    }
    &.l-two-col--reverse {
      &__col-1 {
        grid-column: 2/3;
      }

      &__col-2 {
        grid-column: 1/3;
      }
    }
  }
  .wirnshofer {
    .l-two-col {
      &__col-1,
      &__col-2 {
        grid-row: 1/1;
      }
      &__col-1 {
        padding-top: var(--space-64);
        grid-column: 1/1;
        z-index: 1;
      }

      &__col-2 {
        grid-column: 2/2;
      }
    }
    .content {
      &__title {
        max-width: unset;
      }
    }
  }
  .l-two-col--reverse {
    .l-two-col {
      &__col-1,
      &__col-2 {
        grid-column: 1/1;
        grid-row: 1;
      }
      &__col-1 {
        grid-column: 2/2;
        padding-inline: clamp(var(--space-16), 5vw, var(--space-128));
        justify-content: center;
        align-items: center;
      }
      &__col-2 {
        grid-column: 1/1;
      }
    }
  }
}
