@use '../abstracts/mixins';
.footer {
  background-color: var(--color-secondary);
  display: grid;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  padding-inline: clamp(var(--space-8), 5vw, var(--space-128));
  padding-block: clamp(var(--space-16), 10vw, 0.75 * var(--space-128))
    var(--space-32);
  grid-gap: var(--space-32);

  address,
  a,
  p {
    margin: 0;
    font-style: normal;
    font-size: var(--footnote-fs);
    line-height: var(--footnote-lh);
    letter-spacing: var(--footnote-ls);
    font-weight: var(--footnote-fw);
    text-transform: uppercase;
  }

  &__headline,
  &__link {
    display: block;
    font-size: var(--footnote-headline-fs);
    line-height: var(--footnote-headline-lh);
    letter-spacing: var(--footnote-headline-ls);
    font-weight: var(--footnote-headline-fw);
    margin-bottom: var(--space-4);
    text-transform: uppercase;
  }

  &__links,
  &__contact {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__contact {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column: 1/1;
    grid-gap: clamp(var(--space-16), 8vw, var(--space-32));
    max-width: 320px;
    margin: 0 auto 0 0;
    &--m {
    }
  }

  &__link,
  a {
    color: var(--color-typography);
    text-decoration: none;
    padding: 0.5em 0;

    &:hover,
    &:active,
    &:focus {
    }
  }

  .button--tertiary {
    border: 1.5px solid var(--color-white);
    color: var(--color-typography);

    &:hover,
    &:active,
    &:focus {
      background-color: var(--color-white);
    }
  }
}
@include mixins.respond-to('xs') {
  .footer {
    grid-template-columns: 1fr 1fr;
    &__contact {
      grid-column: 1/1;
      grid-row: 1/1;
      &--m {
        grid-column: 2/2;
        grid-row: 1/1;
      }
    }
    address {
      grid-column: 1/1;
    }
    p {
      grid-column: 2/2;
    }
    &__links {
      grid-column: 1/-1;
      grid-row: 3/3;
      justify-content: flex-start;
      gap: var(--space-32);
    }
  }
}
@include mixins.respond-to('s') {
  .footer {
    grid-template-columns: 1fr 25% 25%;
    grid-template-rows: auto auto;
    grid-gap: var(--space-16) 0;
    &__contact {
      grid-column: 1/1;
      grid-row: 1/1;
      &--m {
        grid-column: 2/-1 !important;
        grid-row: 2/2;
      }
    }
    address {
      grid-column: 2/2;
    }
    p {
      grid-column: 3/3;
    }
    &__links {
      grid-column: 1/-1;
      grid-row: 2/-1;
      justify-content: flex-start;
      gap: var(--space-32);
      padding-block-start: clamp(
        var(--space-16),
        10vw,
        0.75 * var(--space-128)
      );
    }
  }
}
@include mixins.respond-to('m') {
  .footer {
    &__contact {
      display: flex;
      justify-content: flex-start;
    }
  }
}
@include mixins.respond-to('l') {
  .footer {
    &__contact {
      .button {
        width: 96px;
        height: 96px;
        svg {
          width: 58px;
          height: 58px;
        }
      }
    }
  }
}

@include mixins.respond-to('xl') {
  .footer {
    &__contact {
      .button {
        width: 112px;
        height: 112px;
        svg {
          width: 67px;
          height: 67px;
        }
      }
    }
  }
}
