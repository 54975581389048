@use '../abstracts/mixins';

.wirnshofer-owner {
  --inline-end-shift: clamp(24px, 1rem + 3vw, 128px);
  transform: translateX(calc(-1 * var(--inline-end-shift)));
  .store-image {
    margin: 0;
    picture {
      aspect-ratio: 16/10;
      img {
        aspect-ratio: 16/10;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
  figcaption {
    margin: 0;
    padding-top: 2em;
    display: block;
    font-size: clamp(12px, 1vw, 20px);
    font-weight: 700;
    line-height: 1.2;
    color: #c4ad88;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    max-width: 30ch;
    margin-left: auto;
    margin-right: 4em;
  }
}
@include mixins.respond-to('s') {
  .wirnshofer-owner {
    padding-inline-end: calc(5 * clamp(var(--space-8), 5vw, var(--space-128)));
    transform: translateX(
      calc(-1 * clamp(var(--space-8), 5vw, var(--space-128)))
    );
    figcaption {
      font-size: clamp(14px, 1vw, 20px);
    }
  }
}

@include mixins.respond-to('s') {
}
