.page-wrapper {
  position: relative;
}
.announcement {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  background-color: var(--color-beige-soft);
  padding-inline: clamp(var(--space-16), 5vw, var(--space-128));
  padding-block: 32px;
  max-height: 200px;

  h2 {
    font-family: 'Fellix', sans-serif;
    text-transform: uppercase;
    font-weight: var(--title-2-fw);
    letter-spacing: var(--title-2-ls, 0);
    font-size: var(--title-2-fs);
    line-height: var(--title-2-lh);
    padding-bottom: 0.5ch;
  }
  p {
    font-family: 'Fellix', sans-serif;
    font-weight: var(--body-fw);
    letter-spacing: var(--body-ls, 0);
    font-size: var(--body-fs);
    line-height: var(--body-lh);
  }
  &--open {
    display: none;
  }
}
