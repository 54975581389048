@use '../abstracts/mixins';

.history {
  --history-gap: clamp(var(--space-8), 3vw, var(--space-16));
  display: grid;
  gap: var(--history-gap);
  padding-block: var(--history-gap);
  padding-inline-start: calc(var(--history-gap) * 1);
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 1fr;
  align-items: flex-start;
  &__entry {
    display: grid;
    padding-inline-end: calc(var(--history-gap) * 2);
    border-bottom-left-radius: 100vmax;
    border-top-left-radius: 100vmax;
    border: 1.5px solid var(--color-beige-middle);
    grid-column: 1/-1;
    padding-block: var(--history-gap);
    grid-template-columns: repeat(5, 1fr);
  }
  &__year {
    @extend .t-caption-1;
    color: var(--color-beige-dark);
    padding-inline-start: 24px;

    grid-column: 1/-1;
  }
  &__title {
    @extend .t-title-2;
    font-size: clamp(16px, 4vw, 22px);
    grid-column: 1/-1;
    padding-inline-start: 24px;
  }
}

@include mixins.respond-to('xs') {
  .history {
    &__entry {
      grid-column: 1/-1;
    }
    &__title {
      grid-column: 2/-1;
      padding-inline-start: 0;
      font-size: var(--title-2-fs);
    }
    &__year {
      text-align: center;
      grid-column: 2/1;
    }
  }
}

@include mixins.respond-to('m') {
  .history {
    --history-gap: clamp(var(--space-16), 3vw, var(--space-64));
    grid-template-columns: repeat(7, 1fr);
    padding-inline-start: calc(var(--history-gap) * 2);
    &__entry {
      grid-column: 2/-1;
    }
    &__title {
    }
    &__year {
    }
  }
}
