@use '../abstracts/mixins';
.icons-section {
  padding-inline: clamp(var(--space-8), 5vw, var(--space-128));
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: clamp(var(--space-16), 10vw, var(--space-64));

  --icon-in-row: 2;
  &--title {
    margin-inline-start: auto;
    margin-inline-end: 0;
    max-inline-size: 22ch;
    @extend .t-title-1;
  }
  &__content {
    display: flex;
    justify-content: flex-start;
    flex: 0 0 auto;
    padding-bottom: 1.5rem;
  }
  .content__text {
  }
  &__icons {
    display: grid;
    grid-template-columns: repeat(var(--icon-in-row), 1fr);
    gap: 2rem 1rem;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  &__icon {
    flex: 0 0 auto;
    display: grid;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: flex-start;
    font-size: clamp(14px, 3vw, 20px);
    gap: 1em;
    svg {
      display: block;
      width: 96px;
      height: 96px;
    }
    span {
      display: block;
      font-size: clamp(12px, 1vw, 20px);
      font-weight: 700;
      line-height: 1.2;
      color: #c4ad88;
      text-align: center;
      letter-spacing: 0.15em;
      text-transform: uppercase;
    }
  }
}
@include mixins.respond-to('xs') {
  .werte {
    --icon-in-row: 2;
  }
  .service {
    --icon-in-row: 3;
  }
  .icons-section {
    &__icons {
    }
  }
}
@include mixins.respond-to('s') {
  .werte,
  .service {
    --icon-in-row: 2;
  }
  .icons-section {
    flex-direction: row;
    justify-content: space-around;
    &__content {
      justify-content: center;
    }
    &__icons {
      gap: 4em;
    }
    .content__text {
      max-width: 26ch;
    }
    &__icon {
      font-size: clamp(12px, 3vw, 20px);
      gap: 1em;
      svg {
        width: unset;
        height: unset;
      }
      span {
        font-size: clamp(14px, 1vw, 20px);
      }
    }
  }
}
@include mixins.respond-to('l') {
  .icons-section {
    &__icons {
      gap: 6em;
    }
    &__icon {
      svg {
        width: 11vw;
        height: 11vw;
      }
    }
  }
}
@include mixins.respond-to('xll') {
  .service {
    --icon-in-row: 3;
  }
  .icons-section {
    &__icons {
      gap: 5vw;
    }
    &__icon {
      svg {
        width: 13vw;
        height: 13vw;
      }
    }
  }
}
