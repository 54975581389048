@use '../abstracts/mixins';
.content {
  //padding-inline: clamp(var(--space-16), 10vw, var(--space-128));
  display: flex;
  flex-direction: column;
  gap: var(--space-32);

  &__title {
    @extend .t-title-1;
  }

  &__text {
    text-transform: none;
    max-width: 35ch;
    @extend .t-body;
    font-style: normal;
    &--bold {
      font-weight: 500;
    }
  }

  &__buttons {
    display: grid;
    grid-gap: var(--space-8);
    justify-content: flex-start;
    margin-bottom: var(--space-32);
  }
}

.content-with-slide {
  padding-inline-end: calc(clamp(var(--space-16), 5vw, var(--space-128)) * 2);
  padding-inline-start: clamp(var(--space-16), 5vw, var(--space-128));
  padding-bottom: clamp(var(--space-16), 5vw, var(--space-128));
  .content__text {
    max-width: 30ch;
  }
}

@include mixins.respond-to('m') {
  .content {
    padding-block-end: var(--title-1-fs);
    &__title + &__buttons,
    &__text + &__buttons {
      margin-top: var(--space-32);
    }
  }
  .content-with-slide {
    grid-template-columns: 4fr 8fr;
  }
}
