@use 'sass:math';
@use './variables';
/* Mixins */
@mixin clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin font($font) {
  font-family: $font;
  -webkit-text-size-adjust: none;
  /*auto-zoom auf iphone ausschalten*/
  -ms-text-size-adjust: none;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  word-wrap: break-word;
}

@mixin bc-transition($time) {
  -webkit-transition: all $time linear;
  -moz-transition: all $time linear;
  -o-transition: all $time linear;
  -ms-transition: all $time linear;
  transition: all $time linear;
}

@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin respond-to($breakpoint) {
  // Retrieves the value from the key
  $value: map-get(variables.$breakpoints, $breakpoint);
  // If the key exists in the map
  @if $value != null {
    // Prints a media query based on the value
    @media (min-width: $value) {
      @content;
    }
  }
  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. " + "Please make sure it is defined in `$breakpoints` map.";
  }
}
@mixin typo-respond-to($breakpoint) {
  // Retrieves the value from the key
  $value: map-get(variables.$typo-breakpoints, $breakpoint);
  // If the key exists in the map
  @if $value != null {
    // Prints a media query based on the value
    @media (min-width: $value) {
      @content;
    }
  }
  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. " + "Please make sure it is defined in `$breakpoints` map.";
  }
}

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, $number * 0 + 1);
  }
  @return $number;
}

@function px2rem($px, $base-font-size: 16px) {
  @if unitless($px) {
    @warn "Assuming #{$px} to be in pixels, attempting to convert it into pixels.";
    @return px2rem($px * 1px, $base-font-size);
  } @else if unit($px) == rem {
    @return $px;
  }
  @return math.div($px, $base-font-size) * 1rem;
}

//Based on https://websemantics.uk/tools/responsive-font-calculator/
@mixin fluidFontSize(
  $property,
  $minFS,
  $maxFS,
  $breakpointMin,
  $breakpointMax
) {
  $mqMin: map-get(variables.$typo-breakpoints, $breakpointMin);
  $mqMax: map-get(variables.$typo-breakpoints, $breakpointMax);
  $min_viewport: strip-unit($mqMin);
  $max_viewport: strip-unit($mqMax);
  $min_font_size: strip-unit($minFS);
  $max_font_size: strip-unit($maxFS);
  $XX: math.div($mqMin, 100);
  $YY: math.div(
    100 * ($max-font-size - $min-font-size),
    $max-viewport - $min-viewport
  );
  $ZZ: px2rem($minFS);
  #{'--'}#{$property}-fs: clamp(
    #{$minFS},
    #{calc(#{$ZZ} + ((1vw - #{$XX}) * #{$YY}))},
    #{$maxFS}
  );
}

@mixin responsiveTypo(
  $property,
  $minFS,
  $maxFS,
  $lineHeight,
  $fontWeight,
  $letterSpacing,
  $breakpointMin,
  $breakpointMax
) {
  @debug $maxFS;
  @if $maxFS == $minFS {
    #{'--'}#{$property}-fs: $minFS;
  } @else {
    @include fluidFontSize(
      $property,
      $minFS,
      $maxFS,
      $breakpointMin,
      $breakpointMax
    );
  }

  @if $lineHeight != null {
    #{'--'}#{$property}-lh: $lineHeight;
  }
  @if $fontWeight != null {
    #{'--'}#{$property}-fw: $fontWeight;
  }
  @if $letterSpacing != null {
    #{'--'}#{$property}-ls: $letterSpacing;
  }
}
