@use '../abstracts/mixins';

.navigation {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: 0.1s;
  background-color: white;
  z-index: 1000;
  padding-block: var(--space-128);
  display: flex;
  justify-content: center;
  box-shadow: 0 0 0 100vmax #fff;
  ul {
    justify-content: center;
    align-items: center;
    display: grid;
    gap: clamp(0.75rem, 2vmin + 1px, 2rem);
    padding: 0;
    margin: 0;
    padding-inline: clamp(var(--space-16), 5vw, var(--space-128));
    z-index: 1005;
    position: relative;
    li {
      list-style: none;
      display: flex;
      place-content: center;
      place-items: center;

      a {
        @extend .t-title-2;
        display: inline-block;
        text-decoration: none;
        color: var(--color-gray);
        transition: all 300ms ease-in-out;
        line-height: 1.35;
        &:hover {
          color: var(--color-gray);
          font-weight: 500;
          transition: all 300ms ease-in-out;
        }
      }
    }
  }
  &--open {
    opacity: 1;
    visibility: visible;
    height: 100vh;
  }
}

.no-scroll {
  overflow: hidden;
}
@include mixins.respond-to('s') {
  .navigation {
  }
}
@include mixins.respond-to('m') {
  .navigation {
    width: 60%;
    padding-block: clamp(var(--space-128), 10vmin, var(--space-128) * 2);
    box-shadow: none;
  }
  .no-scroll {
    &,
    .header-nav__toggle {
      padding-right: 10px;
    }
  }
}
