@use '../abstracts/mixins';
.header {
  display: grid;
  //grid-gap: var(--space-32);
  padding-block-end: var(--space-32);
  &__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
    clip-path: ellipse(74% 76% at 67% 14%);
  }
  &__content {
    padding-inline: clamp(var(--space-16), 5vw, var(--space-128));

    display: grid;
    gap: var(--space-32);
    h2 {
      max-inline-size: 12ch;
      @extend .t-title-1;
    }
    p {
      text-transform: none;
      max-width: 35ch;
      @extend .t-body;
    }
  }
  &__buttons {
    display: grid;
    grid-gap: var(--space-8);
    justify-content: flex-start;
  }
}
.header--image {
  grid-template-rows: max(200px, min(55vh, 500px)) 1fr;
  padding-block-end: var(--space-32);
  .header {
    &__image {
      clip-path: ellipse(74% 76% at 67% 14%);
      grid-row: 1/3;
      grid-column: 1/1;
    }
    &__content {
      grid-row: 2/3;
      grid-column: 1/1;
    }
  }
}
.header--slide {
  grid-template-rows: max(200px, min(55vh, 600px)) 1fr;
  padding: 0;
  .header {
    &__image {
      clip-path: ellipse(74% 76% at 67% 14%);
    }
    &__content {
      h2 {
        @extend .t-title-large;
      }
    }
  }
}
.header--text {
  min-height: unset;
  display: block;
  .header {
    &__content {
      width: 100%;
      padding-block: var(--space-64);

      h2 {
        max-inline-size: unset;
      }
      p {
      }
    }
    &__buttons {
      justify-content: center;
    }
  }
}

.header-nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-block: clamp(var(--space-16), 3vw, var(--space-128));
  padding-inline: clamp(var(--space-16), 5vw, var(--space-128));
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 1002;
  pointer-events: none;
  .svg {
    position: absolute;
    width: 0;
    height: 0;
  }
  &__logo {
    position: relative;
    text-decoration: none;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 2px;
    background: url('assets/static/wirnshofer-logo.png');
    background-size: cover;
    clip-path: url(#my-clip-path);
    height: 61px;
    width: 87px;
    pointer-events: all;
    a {
      position: absolute;
      inset: 0;
    }
  }
  &__toggle {
    pointer-events: all;
    position: relative;
    z-index: 1005;
    svg {
      pointer-events: none;
    }
    &--active {
      //background: url(close.png);
      background-size: 25px;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }
}

@include mixins.respond-to('s') {
  .header {
    min-height: 66vw;

    &__content {
      gap: var(--space-32);
      justify-content: center;
      align-items: center;
      align-content: center;
    }
  }
  .header--image {
    grid-template-columns: 60% 1fr;
    grid-template-rows: 1fr;
    min-height: 75vw;
    grid-gap: clamp(var(--space-16), 1.5vw, 80px);
    .header {
      &__image {
        grid-row: 1/1;
        grid-column: 1/1;
        clip-path: circle(41vw at 18vw 21vw);
      }
      &__content {
        padding-inline-start: 0;

        grid-row: 1/1;
        grid-column: 2/2;
      }
    }
  }
  .header--slide {
    grid-template-columns: 3fr 3fr 7fr;
    grid-template-rows: 1fr;
    .header {
      &__image {
        grid-row: 1/1;
        grid-column: 2/-1;
        clip-path: circle(41vw at 41vw 21vw);
      }
      &__content {
        grid-row: 1/1;
        grid-column: 1/3;
      }
    }
  }
  .header--text {
    min-height: unset;
    padding-block: var(--space-64);

    .header {
      &__content {
        grid-template-columns: 1fr 1fr;
        h2 {
          max-width: unset;
          grid-column: 2/2;
        }
        p {
          grid-column: 2/2;
        }
      }
      &__buttons {
        justify-content: center;
        grid-column: 1/1;
        grid-row: 2/2;
      }
    }
  }

  .header-nav {
    &__logo {
      clip-path: none;
      width: 250px;
      height: 70px;
    }
  }
}

@include mixins.respond-to('m') {
  .header-nav {
    &__logo {
      width: 300px;
      height: 84px;
    }
  }
}
@include mixins.respond-to('xl') {
  .header-nav {
    &__logo {
      width: 350px;
      height: 98px;
    }
  }
}

@include mixins.respond-to('xl') {
  .header-nav {
    &__logo {
      width: 450px;
      height: 126px;
    }
  }
}
