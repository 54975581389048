/* Variables */

/* Colors */
$black: #000;
$white: #fff;

$gray-1: #707070;
$gray-2: #c0c0c0;
$gray-3: #f0f0f0;

/* Element-Colors */
$c_typo_primary: rgba(0, 0, 0, 1);
$c_typo_secondary: rgba(30, 30, 30, 1);

$c_bg_primary: $white;
$c_bg_secondary: $gray-3;

$c_typo_invers: $white;
$c_bg_invers: $black;
$c_bg_hero: $black;

/* Breakpoints */
$breakpoints: (
  'default': 320px,
  'xs': 480px,
  's': 768px,
  'm': 1024px,
  'l': 1200px,
  'xll': 1440px,
  'xl': 1980px
);

$typo-breakpoints: (
  'default': 320px,
  'xxs': 480px,
  'xs': 774px,
  's': 834px,
  'm': 1024px,
  'l': 1280px,
  'xl': 1440px,
  'xxl': 1920px,
  'xxxl': 3840px
);
/* Z-Indexes */
$z-indexes: (
  'modal': 5000,
  'dropdown': 4000,
  'default': 1,
  'below': -1
);

:root {
  /* Color styles */
  --color-gray: rgba(74, 74, 74, 1);
  --color-white: rgba(255, 255, 255, 1);
  --color-beige: rgba(254, 249, 239, 1);
  --color-beige-soft: rgba(240, 230, 213, 1);
  --color-beige-dark: rgba(195, 173, 136, 1);
  --color-beige-middle: rgba(216, 171, 118, 1);

  --color-main: var(--color-beige-dark);
  --color-secondary: var(--color-beige-middle);
  --color-typography: var(--color-gray);
  --color-typography-alt: var(--color-beige-dark);
  --color-black: rgba(0, 0, 0, 1);
  --font-rendering: subpixel-antialiased;
  --font-light: 300;
  --font-regular: 400;
  --font-bold: 500;

  --space-4: 4px;
  --space-8: 8px;
  --space-16: 16px;
  --space-32: 32px;
  --space-64: 64px;
  --space-128: 128px;
}
