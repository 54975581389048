.button {
  transition: all 0.2s ease-in;
  border-radius: 20px;
  @extend .t-body;
  text-decoration: none;
  letter-spacing: 0.15em;
  border-width: 1.5px;
  border-style: solid;
  text-transform: uppercase;

  &--primary {
    background-color: var(--color-beige-middle);
    border-color: var(--color-beige-middle);
    color: var(--color-beige);
    &:hover,
    &:focus {
      border-color: var(--color-beige-middle);
      background-color: transparent;
      color: var(--color-gray);
    }
    &:active {
      background-color: var(--color-gray);
      color: var(--color-beige);
      border-color: var(--color-gray);
    }
  }
  &--secondary {
    border-color: var(--color-beige-middle);
    background-color: transparent;
    color: var(--color-gray);
    &:hover,
    &:focus {
      background-color: var(--color-beige-middle);
      border-color: var(--color-beige-middle);
      color: var(--color-beige);
    }
    &:active {
      border-color: var(--color-gray);
      color: var(--color-gray);
      background-color: transparent;
    }
  }
  &--tertiary {
    background-color: var(--color-beige);
    border-color: var(--color-beige-middle);
    z-index: 999;
    position: relative;
  }
  &--small {
    padding: 14px 49px;
  }
  &--small {
    padding: 14px 49px;
  }
  &--medium {
    padding: 14px 63px;
  }
  &--large {
    padding: 18px 86px;
  }
  &--icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100vmax;
    padding: 0;
    width: 52px;
    height: 52px;
  }
}

.nav-icon {
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: transform 330ms ease-out;

  &__line-menu {
    display: block;
    background-color: var(--color-gray);
    border-radius: 1px;
    width: 100%;
    height: 2px;
    transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
    &--half {
      width: 50%;
    }
    &--start {
      transform-origin: left;
      align-self: flex-end;
    }
    &--end {
      transform-origin: right;
    }
  }
}
.nav-icon--open {
  transform: rotate(-45deg);
  .nav-icon {
    &__line-menu {
      &--start {
        transform: rotate(90deg) translateX(-1px);
      }
      &--end {
        transform: rotate(90deg) translateX(1px);
      }
    }
  }
}

.on-dark {
  .button {
    &--tertiary {
      background-color: transparent;
      border-color: var(--color-beige);
    }
  }
}

.button--large {
  &.button--icon {
    width: 75px;
    height: 75px;
    padding: 0;
  }
}
