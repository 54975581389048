/* Base */

*,
*:after,
*:before {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  vertical-align: baseline;
  font-family: $sans;
  font-style: normal;
}

html {
  -webkit-text-size-adjust: none;
  /*auto-zoom auf iphone ausschalten*/
  -ms-text-size-adjust: none;
  height: 100%;
  text-rendering: optimizeLegibility;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  position: relative;
  background-color: var(--color-beige);
  color: var(--color-typography);
  height: 100%;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #d8a876 var(--color-beige);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: var(--color-beige);
}

*::-webkit-scrollbar-thumb {
  background-color: #d8a876;
  border-radius: 5px;
  border: 2px solid #f0e6d5;
}
