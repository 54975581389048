.wirnshofer-image {
  border-radius: clamp(var(--space-16), 5vw + 1px, 80px);
  max-width: 100%;
  height: auto;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1;
}
img.image {
  max-width: 100%;
  height: auto;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1;
}
.contain {
  object-fit: contain;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
#datenschutz,
#impressum-page {
  padding-inline: clamp(var(--space-16), 10vw, var(--space-128));
  padding-top: 250px;
  @extend .t-body;
  max-width: 50ch;
  margin: 0 auto;
  .markdown p > strong {
    margin-bottom: 1em;
    display: block;
  }
  p + p > strong,
  h4 {
    margin-top: 1em;
  }
  p,
  h4,
  h2 {
    margin-bottom: 0.75em;
  }
}
