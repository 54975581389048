/* Typography */
@use '../abstracts/mixins';

/* jost-regular - latin */
@font-face {
  font-family: 'Fellix';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('../fonts/Fellix-Regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/Fellix-Regular.woff')
      format('woff'); /* Modern Browsers */
}
@font-face {
  font-family: 'Fellix';
  font-style: normal;
  font-weight: 300;
  src: local(''), url('../fonts/Fellix-Light.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/Fellix-Light.woff') format('woff'); /* Modern Browsers */
}
@font-face {
  font-family: 'Fellix';
  font-style: normal;
  font-weight: 500;
  src: local(''), url('../fonts/Fellix-Medium.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/Fellix-Medium.woff')
      format('woff'); /* Modern Browsers */
}

$sans: 'Fellix', sans-serif;
$sans2: sans-serif;
$serif: Georgia, serif;
$serif2: Georgia, serif;
$mono: Menlo, Courier, monospace;

:root {
  @include mixins.responsiveTypo(
    'large-title',
    36px,
    36px,
    1.3,
    300,
    0.1em,
    'default',
    'xxs'
  );
  @include mixins.responsiveTypo(
    'title-1',
    26px,
    28px,
    1.3,
    300,
    0.1em,
    'default',
    'xxs'
  );
  @include mixins.responsiveTypo(
    'title-2',
    22px,
    22px,
    1.3,
    300,
    0.1em,
    'default',
    'xxs'
  );
  @include mixins.responsiveTypo(
    'headline',
    16px,
    16px,
    1.5,
    500,
    0.05em,
    'default',
    'xxs'
  );
  @include mixins.responsiveTypo(
    'body',
    16px,
    18px,
    1.4,
    400,
    0,
    'default',
    'xxs'
  );
  @include mixins.responsiveTypo(
    'callout',
    16px,
    16px,
    1.5,
    500,
    0.05em,
    'default',
    'xxs'
  );
  @include mixins.responsiveTypo(
    'sub-head',
    16px,
    16px,
    1.5,
    400,
    0.05em,
    'default',
    'xxs'
  );
  @include mixins.responsiveTypo(
    'footnote',
    16px,
    16px,
    1.5,
    400,
    0.05em,
    'default',
    'xxs'
  );
  @include mixins.responsiveTypo(
    'footnote-headline',
    16px,
    16px,
    1.5,
    500,
    0.05em,
    'default',
    'xxs'
  );
  @include mixins.responsiveTypo(
    'caption',
    12px,
    12px,
    1.5,
    500,
    0.15em,
    'default',
    'xxs'
  );
  @include mixins.responsiveTypo(
    'button',
    16px,
    16px,
    1.5,
    500,
    0.015em,
    'default',
    'xxs'
  );
  min-height: 0vw; // iOS hack for variable FS
}

@include mixins.typo-respond-to('xxs') {
  :root {
    @include mixins.responsiveTypo(
      'large-title',
      36px,
      40px,
      null,
      null,
      null,
      'xxs',
      'xs'
    );
    @include mixins.responsiveTypo(
      'title-1',
      28px,
      36px,
      null,
      null,
      null,
      'xxs',
      'xs'
    );
    @include mixins.responsiveTypo(
      'title-2',
      22px,
      26px,
      null,
      null,
      null,
      'xxs',
      'xs'
    );
    @include mixins.responsiveTypo(
      'headline',
      16px,
      16px,
      null,
      null,
      null,
      'xxs',
      'xs'
    );
    @include mixins.responsiveTypo(
      'body',
      16px,
      16px,
      null,
      null,
      null,
      'xxs',
      'xs'
    );
    @include mixins.responsiveTypo(
      'callout',
      16px,
      16px,
      null,
      null,
      null,
      'xxs',
      'xs'
    );
    @include mixins.responsiveTypo(
      'sub-head',
      16px,
      16px,
      null,
      null,
      null,
      'xxs',
      'xs'
    );
    @include mixins.responsiveTypo(
      'footnote',
      16px,
      16px,
      null,
      null,
      null,
      'xxs',
      'xs'
    );
    @include mixins.responsiveTypo(
      'footnote-headline',
      16px,
      16px,
      null,
      null,
      null,
      'xxs',
      'xs'
    );
    @include mixins.responsiveTypo(
      'caption',
      12px,
      14px,
      null,
      null,
      null,
      'xxs',
      'xs'
    );
    @include mixins.responsiveTypo(
      'button',
      16px,
      16px,
      null,
      null,
      null,
      'xxs',
      'xs'
    );
  }
}
@include mixins.typo-respond-to('xs') {
  :root {
    @include mixins.responsiveTypo(
      'large-title',
      40px,
      48px,
      null,
      null,
      null,
      'xs',
      's'
    );
    @include mixins.responsiveTypo(
      'title-1',
      36px,
      40px,
      null,
      null,
      null,
      'xs',
      's'
    );
    @include mixins.responsiveTypo(
      'title-2',
      26px,
      28px,
      null,
      null,
      null,
      'xs',
      's'
    );
    // @include mixins.responsiveTypo('headline', 16px, 16px, null, null, null, 'xs', 's');
    // @include mixins.responsiveTypo('body', 16px, 16px, null, null, null, 'xs', 's');
    // @include mixins.responsiveTypo('callout', 12px, 12px, null, null, null, 'xs', 's');
    // @include mixins.responsiveTypo('sub-head', 16px, 16px, null, null, null, 'xs', 's');
    @include mixins.responsiveTypo(
      'footnote',
      12px,
      12px,
      null,
      null,
      null,
      'xs',
      's'
    );
    @include mixins.responsiveTypo(
      'footnote-headline',
      12px,
      12px,
      null,
      null,
      null,
      'xs',
      's'
    );
    @include mixins.responsiveTypo(
      'caption',
      14px,
      18px,
      null,
      null,
      null,
      'xs',
      's'
    );
    // @include mixins.responsiveTypo('button', 16px, 16px, null, null, null,  'xs', 's');
  }
}
@include mixins.typo-respond-to('s') {
  :root {
    @include mixins.responsiveTypo(
      'large-title',
      48px,
      56px,
      null,
      null,
      null,
      's',
      'm'
    );
    @include mixins.responsiveTypo(
      'title-1',
      40px,
      48px,
      null,
      null,
      null,
      's',
      'm'
    );
    @include mixins.responsiveTypo(
      'title-2',
      28px,
      42px,
      null,
      null,
      null,
      's',
      'm'
    );
    @include mixins.responsiveTypo(
      'headline',
      16px,
      18px,
      null,
      null,
      null,
      's',
      'm'
    );
    @include mixins.responsiveTypo(
      'body',
      16px,
      18px,
      null,
      null,
      null,
      's',
      'm'
    );
    @include mixins.responsiveTypo(
      'callout',
      12px,
      14px,
      null,
      null,
      null,
      's',
      'm'
    );
    @include mixins.responsiveTypo(
      'sub-head',
      16px,
      18px,
      null,
      null,
      null,
      's',
      'm'
    );
    @include mixins.responsiveTypo(
      'footnote',
      12px,
      14px,
      null,
      null,
      null,
      's',
      'm'
    );
    @include mixins.responsiveTypo(
      'footnote-headline',
      12px,
      14px,
      null,
      null,
      null,
      's',
      'm'
    );
    @include mixins.responsiveTypo(
      'caption',
      18px,
      20px,
      null,
      null,
      null,
      's',
      'm'
    );
    @include mixins.responsiveTypo(
      'button',
      16px,
      16px,
      null,
      null,
      null,
      's',
      'm'
    );
  }
}
@include mixins.typo-respond-to('m') {
  :root {
    @include mixins.responsiveTypo(
      'large-title',
      56px,
      64px,
      null,
      null,
      null,
      'm',
      'l'
    );
    @include mixins.responsiveTypo(
      'title-1',
      48px,
      56px,
      null,
      null,
      null,
      'm',
      'l'
    );
    @include mixins.responsiveTypo(
      'title-2',
      36px,
      36px,
      null,
      null,
      null,
      'm',
      'l'
    );
    @include mixins.responsiveTypo(
      'headline',
      18px,
      22px,
      null,
      null,
      null,
      'm',
      'l'
    );
    @include mixins.responsiveTypo(
      'body',
      22px,
      22px,
      null,
      null,
      null,
      'm',
      'l'
    );
    @include mixins.responsiveTypo(
      'callout',
      14px,
      16px,
      null,
      null,
      null,
      'm',
      'l'
    );
    @include mixins.responsiveTypo(
      'sub-head',
      18px,
      22px,
      null,
      null,
      null,
      'm',
      'l'
    );
    @include mixins.responsiveTypo(
      'footnote',
      14px,
      16px,
      null,
      null,
      null,
      'm',
      'l'
    );
    @include mixins.responsiveTypo(
      'footnote-headline',
      14px,
      16px,
      null,
      null,
      null,
      'm',
      'l'
    );
    // @include mixins.responsiveTypo('caption', 20px, 18px, null, null, null, 'm', 'l');
    // @include mixins.responsiveTypo('button', 16px, 16px, null, null, null, 'm', 'l');
  }
}
@include mixins.typo-respond-to('l') {
  :root {
    @include mixins.responsiveTypo(
      'large-title',
      64px,
      80px,
      null,
      null,
      null,
      'l',
      'xl'
    );
    @include mixins.responsiveTypo(
      'title-1',
      56px,
      64px,
      null,
      null,
      null,
      'l',
      'xl'
    );
    @include mixins.responsiveTypo(
      'title-2',
      36px,
      42px,
      null,
      null,
      null,
      'l',
      'xl'
    );
    @include mixins.responsiveTypo(
      'headline',
      22px,
      24px,
      null,
      null,
      null,
      'l',
      'xl'
    );
    @include mixins.responsiveTypo(
      'body',
      22px,
      24px,
      null,
      null,
      null,
      'l',
      'xl'
    );
    @include mixins.responsiveTypo(
      'callout',
      16px,
      16px,
      null,
      null,
      null,
      'l',
      'xl'
    );
    @include mixins.responsiveTypo(
      'sub-head',
      22px,
      24px,
      null,
      null,
      null,
      'l',
      'xl'
    );
    //@include mixins.responsiveTypo('footnote', 16px, 16px, null, null, null, 'l', 'xl');
    //@include mixins.responsiveTypo('footnote-headline', 16px, 14px, null, null, null, 'l', 'xl');
    @include mixins.responsiveTypo(
      'caption',
      18px,
      20px,
      null,
      null,
      null,
      'l',
      'xl'
    );
    @include mixins.responsiveTypo(
      'button',
      16px,
      18px,
      null,
      null,
      null,
      'l',
      'xl'
    );
  }
}
@include mixins.typo-respond-to('xl') {
  :root {
    @include mixins.responsiveTypo(
      'large-title',
      80px,
      122px,
      null,
      null,
      null,
      'xl',
      'xxl'
    );
    @include mixins.responsiveTypo(
      'title-1',
      64px,
      80px,
      null,
      null,
      null,
      'xl',
      'xxl'
    );
    @include mixins.responsiveTypo(
      'title-2',
      42px,
      56px,
      null,
      null,
      null,
      'xl',
      'xxl'
    );
    @include mixins.responsiveTypo(
      'headline',
      24px,
      28px,
      null,
      null,
      null,
      'xl',
      'xxl'
    );
    @include mixins.responsiveTypo(
      'body',
      24px,
      28px,
      null,
      null,
      null,
      'xl',
      'xxl'
    );
    @include mixins.responsiveTypo(
      'callout',
      16px,
      18px,
      null,
      null,
      null,
      'xl',
      'xxl'
    );
    @include mixins.responsiveTypo(
      'sub-head',
      24px,
      28px,
      null,
      null,
      null,
      'xl',
      'xxl'
    );
    @include mixins.responsiveTypo(
      'footnote',
      16px,
      18px,
      null,
      null,
      null,
      'l',
      'xl'
    );
    @include mixins.responsiveTypo(
      'footnote-headline',
      14px,
      18px,
      null,
      null,
      null,
      'l',
      'xl'
    );
    @include mixins.responsiveTypo(
      'caption',
      20px,
      28px,
      null,
      null,
      null,
      'xl',
      'xxl'
    );
    @include mixins.responsiveTypo(
      'button',
      18px,
      22px,
      null,
      null,
      null,
      'xl',
      'xxl'
    );
  }
}
@include mixins.typo-respond-to('xxl') {
  :root {
    @include mixins.responsiveTypo(
      'large-title',
      122px,
      224px,
      null,
      null,
      null,
      'xxl',
      'xxxl'
    );
    @include mixins.responsiveTypo(
      'title-1',
      80px,
      144px,
      null,
      null,
      null,
      'xxl',
      'xxxl'
    );
    @include mixins.responsiveTypo(
      'title-2',
      56px,
      80px,
      null,
      null,
      null,
      'xxl',
      'xxxl'
    );
    @include mixins.responsiveTypo(
      'headline',
      28px,
      48px,
      null,
      null,
      null,
      'xxl',
      'xxxl'
    );
    @include mixins.responsiveTypo(
      'body',
      28px,
      48px,
      null,
      null,
      null,
      'xxl',
      'xxxl'
    );
    @include mixins.responsiveTypo(
      'callout',
      18px,
      36px,
      null,
      null,
      null,
      'xxl',
      'xxxl'
    );
    @include mixins.responsiveTypo(
      'sub-head',
      28px,
      48px,
      null,
      null,
      null,
      'xxl',
      'xxxl'
    );
    @include mixins.responsiveTypo(
      'footnote',
      18px,
      36px,
      null,
      null,
      null,
      'l',
      'xxl'
    );
    @include mixins.responsiveTypo(
      'footnote-headline',
      18px,
      36px,
      null,
      null,
      null,
      'l',
      'xxl'
    );
    @include mixins.responsiveTypo(
      'caption',
      28px,
      36px,
      null,
      null,
      null,
      'xxl',
      'xxxl'
    );
    @include mixins.responsiveTypo(
      'button',
      22px,
      46px,
      null,
      null,
      null,
      'xxl',
      'xxxl'
    );
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
address {
  margin: 0;
}

.t-title-large {
  font-family: $sans;
  text-transform: uppercase;
  font-weight: var(--large-title-fw);
  letter-spacing: var(--large-title-ls, 0);
  font-size: var(--large-title-fs);
  line-height: var(--large-title-lh);
}
.t-title-1 {
  font-family: $sans;
  text-transform: uppercase;
  font-weight: var(--title-1-fw);
  letter-spacing: var(--title-1-ls, 0);
  font-size: var(--title-1-fs);
  line-height: var(--title-1-lh);
}
.t-title-2 {
  font-family: $sans;
  text-transform: uppercase;
  font-weight: var(--title-2-fw);
  letter-spacing: var(--title-2-ls, 0);
  font-size: var(--title-2-fs);
  line-height: var(--title-2-lh);
}
.t-body {
  font-family: $sans;
  font-weight: var(--body-fw);
  letter-spacing: var(--body-ls, 0);
  font-size: var(--body-fs);
  line-height: var(--body-lh);
}
.t-caption-1 {
  font-family: $sans;
  text-transform: uppercase;
  font-weight: var(--caption-fw);
  letter-spacing: var(--caption-ls, 0);
  font-size: var(--caption-fs);
  line-height: var(--caption-lh);
}
