.input {
  border: 1.5px solid var(--color-beige-dark);
  background-color: transparent;
  width: 100%;
  @extend .t-body;
  padding: calc(var(--body-fs) * 0.875) var(--body-fs);
  border-radius: calc(var(--body-fs) * var(--body-lh));
  color: var(--color-beige-middle);
  &::placeholder {
    color: var(--color-gray);
  }
  &:focus,
  &:focus-within,
  &:focus-visible {
    border-color: transparent;
    outline: 1.5px solid var(--color-gray);
  }
  &:not(:focus):not(:placeholder-shown):invalid {
    background-color: var(--color-gray);
    border-color: var(--color-gray);
  }
  &:not(:focus):not(:placeholder-shown):invalid ~ .error-message {
    display: block;
  }
  & + .error-message {
    margin-top: calc(var(--form-gap, 16px) / 2);
  }
}

.form {
  --form-gap: clamp(var(--space-16), 2vw, var(--space-32));
  display: grid;
  max-width: 480px;
  gap: clamp(var(--space-16), 2vw, var(--space-32));
}
.error-message {
  display: none;
  font-weight: bold;
  color: var(--color-gray);
  text-align: center;
}
.form-response {
  @extend .t-body;
}
